<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade class=" mt-2" style="height:100vh">
    <b-card>
      <validation-observer ref="surveyRules">
        <b-form>
          <b-row align-h="end" class="mt-2">
            <!-- Date -->
            <b-col cols="12" sm="6" md="4" lg="2" xl="2">
              <b-form-group>
                <label for="date">Date</label>
                <validation-provider name="date" #default="{ errors }" rules="required">
                  <flat-pickr class="form-control" :config="{ dateFormat: 'm-d-Y' }" placeholder="MM-DD-YYYY" v-model="quoteData.date" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <!-- Offering Code -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group>
                <label for="offeringCode">Offering Code</label>
                <validation-provider name="offeringCode" #default="{ errors }" rules="required">
                  <b-form-input id="offeringCode" type="text" v-model="quoteData.offeringCode"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Customer No -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Customer" label-for="customerNo">
                <validation-provider name="customerNo" #default="{ errors }" rules="required">
                  <v-select v-model="quoteData.customerNo" :taggable="true" :loading="dropdownLoading" :value="quoteData.customerNo" :options="dropCustomer" label="name" :reduce="(val) => val.id" @input="getCustomerDetails"> </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Organisation -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Organisation" label-for="organisation">
                <validation-provider name="organisation" #default="{ errors }" rules="required">
                  <b-form-input id="organisation" type="text" v-model="quoteData.organisation"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Adress -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Address" label-for="address">
                <validation-provider name="address" #default="{ errors }" rules="required">
                  <b-form-input id="address" type="text" v-model="quoteData.address"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Contract -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Contract" label-for="contractName">
                <validation-provider name="contractName" #default="{ errors }" rules="required">
                  <b-form-input id="contractName" type="number" v-model="quoteData.contractName"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Total Contract Price -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group label="Total Contract Price" label-for="totalContractPrice1">
                <validation-provider name="totalContractPrice1" #default="{ errors }" rules="required">
                  <b-form-input id="totalContractPrice1" type="number" v-model="totalPrice"> </b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xl="12">
              <hr />
            </b-col>

            <!-- Contract Details Section -->
            <b-card-body class="invoice-padding form-item-section">
              <!-- div ref="form" class="repeater-form" :style="{ height: trHeight }" -->
              <div ref="contractForm" class="repeater-form">
                <b-row v-for="(item, index) in quoteData.contracts" :key="index" ref="row" class="pb-2 border rounded mb-1">
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" class="mt-1" lg="5">
                          <b>Contract Item</b>
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                    <div class="d-flex flex-row ">
                      <b-row class="flex-grow-1 pr-2 pl-2">
                        <!-- Single Item Form Headers -->

                        <b-col cols="12" lg="3" xl="3" md="3" class="mb-2">
                          <span class="mb-2">
                            Course/Workshop Name
                          </span>
                          <validation-provider name="courseWorkshopName" #default="{ errors }" rules="required">
                            <v-select v-model="item.courseWorkshopName" :taggable="true" :loading="dropdownLoading" :value="item.courseWorkshopName" :options="dropCourseName" @input="getCourseCode(item)"> </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12" lg="3" xl="3" md="3" class="mb-2">
                          <span class="mb-2">
                            Service Delivery Code
                          </span>
                          <validation-provider name="serviceDeliveryCode" #default="{ errors }" rules="required">
                            <v-select v-model="item.serviceDeliveryCode" :taggable="true" :loading="dropdownLoading" @input="getCourseDetails(item)" :value="item.serviceDeliveryCode" :options="dropCourseCode" label="title" :reduce="(val) => val.id"> </v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12" lg="3" xl="3" md="3" class="mb-2">
                          <span class="mb-2">
                            Presenter
                          </span>
                          <validation-provider name="presenter" #default="{ errors }" rules="required">
                            <v-select v-model="item.presenter" :taggable="true" :loading="dropdownLoading" :value="item.presenter" :options="dropTeachers" label="title" :reduce="(val) => val.id"> </v-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12" lg="3" xl="3" md="3" class="mb-2">
                          <span class="mb-2">
                            Participants
                          </span>
                          <validation-provider name="participants" #default="{ errors }" rules="required">
                            <b-form-input id="participants" v-model="item.participants" type="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>

                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeContractFormItem(index)" />
                      </div>
                    </div>

                    <b-row class="flex-grow-1  pr-2 pl-2">
                      <b-col cols="12" lg="3" xl="3" md="3" :key="index" v-for="(day, index) in item.days">
                        <span class="mb-2">
                          {{ day.dayName }}
                        </span>
                        <validation-provider :name="day.name" #default="{ errors }" rules="required">
                          <flat-pickr :id="day.name" class="form-control" :config="{ dateFormat: 'm-d-Y' }" placeholder="MM-DD-YYYY" v-model="day.value" />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <div class="d-flex float-right">
                      <b-col cols="12" lg="12" xl="12" md="12" class="mb-2">
                        <span class="mb-2">
                          Cost
                        </span>
                        <validation-provider name="cost" #default="{ errors }" rules="required">
                          <b-form-input id="cost" v-model="item.cost" type="number" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button class="mt-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewContractsInContractForm">
                Add Item
              </b-button>
            </b-card-body>

            <b-col cols="12" xl="12">
              <hr />
            </b-col>
          </b-row>
          <b-row align-h="end" class="mt-2">
            <!-- Date -->
            <b-col cols="12" sm="6" md="4" lg="4" xl="4">
              <b-form-group>
                <label for="totalContractPrice">Total Contract Price</label>
                <validation-provider name="totalContractPrice" #default="{ errors }" rules="required">
                  <b-form-input id="totalContractPrice" type="text" v-model="totalPrice"> </b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mb-5 mt-2">
              <b-button type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import settingsStoreModule from '../../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import { t } from '@/@core/libs/i18n/utils';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    flatPickr,
    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const WORKSHOP_APP_STORE_MODULE_NAME = 'workshop';
    // Register module
    if (!store.hasModule(WORKSHOP_APP_STORE_MODULE_NAME)) store.registerModule(WORKSHOP_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORKSHOP_APP_STORE_MODULE_NAME)) store.unregisterModule(WORKSHOP_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var quoteData = {
      date: null,
      offeringCode: null,
      customerNo: null,
      organisation: null,
      address: null,
      contractName: null,
      totalContractPrice: null,
      contracts: [
        {
          serviceDeliveryCode: null,
          courseWorkshopName: null,
          presenter: null,
          participants: null,
          cost: null,
          days: [
            {
              dayName: 'First Day',
              value: null,
            },
            {
              dayName: 'Last Day',
              value: null,
            },
          ],
          cost: null,
        },
      ],
    };

    var contractFormBlankContract = {
      serviceDeliveryCode: null,
      courseWorkshopName: null,
      presenter: null,
      participants: null,
      cost: null,
      days: [
        {
          dayName: 'First Day',
          value: null,
        },
        {
          dayName: 'Last Day',
          value: null,
        },
      ],
      cost: null,
    };

    return {
      quoteData: quoteData,
      contractFormBlankContract: contractFormBlankContract,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,
      dropdownLoading: false,
      formShow: false,
      groups: [],
      allGroupsData: [],
      ans: null,
      dropCourseName: [],
      dropCourseDetails: [],
      dropCourseCode: [],
      dropCustomer: [
        { id: 1, name: 'Cuneyt IRMAK', organisation: 'A School', address: 'A Street, No:16' },
        { id: 2, name: 'Cuneyt IRMAK', organisation: 'B School', address: 'B Street, No:16' },
        { id: 3, name: 'Cuneyt IRMAK', organisation: 'C School', address: 'C Street, No:16' },
      ],
      dropTeachers: [],
      totalPrice: 0,
      fields: [
        {
          key: 'questions',
          label: 'questions',
          thStyle: { width: '80%' },
        },
        {
          key: 'answer',
          label: 'answer',
          thStyle: { width: '20%' },
        },
      ],
    };
  },
  mounted() {
    //this.getGroupWithQuestions();
    // this.getAllGroupsData();
  },

  created() {
    this.getCourseName();
    this.getTeachers();
  },

  watch: {
    quoteData: {
      handler: function(val, before) {
        this.totalPrice = 0;
        val.contracts.forEach((element, index) => {
          this.totalPrice = this.totalPrice + element.cost;
        });
      },
      deep: true,
    },
  },

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getTeachers() {
      store
        .dispatch('workshop/dropdownPresenterList')
        .then((response) => {
          response.data.forEach((element) => {
            this.dropTeachers.push(element);
          });
        })
        .catch((error) => {});
    },

    getCourseName() {
      store
        .dispatch('workshop/dropdownWorkshopList')
        .then((response) => {
          response.data.forEach((element) => {
            if (element.courseName != null) {
              this.dropCourseName.push(element.courseName);
              this.dropCourseDetails.push(element);
            }
          });
        })
        .catch((error) => {});
    },

    getCourseCode(item) {
      this.dropCourseCode = this.dropCourseDetails.filter((x) => x.courseName == item.courseWorkshopName)[0].courseList;
    },

    getCourseDetails(item) {
      item.participants = this.dropCourseCode.filter((x) => x.id == item.serviceDeliveryCode)[0].cofvCount;
      item.cost = this.dropCourseCode.filter((x) => x.id == item.serviceDeliveryCode)[0].total;
      let days = this.dropCourseCode.filter((x) => x.id == item.serviceDeliveryCode)[0].days;

      item.days = [
        {
          dayName: 'First Day',
          value: null,
        },
        {
          dayName: 'Last Day',
          value: null,
        },
      ];

      let n = days - 1;

      while (n > 1) {
        item.days.splice(1, 0, {
          dayName: 'Day ' + n,
          value: null,
        });
        n -= 1;
      }

      this.quoteData.totalContractPrice += item.cost;
    },

    getCustomerDetails() {
      let customer = this.dropCustomer.filter((x) => x.id == this.quoteData.customerNo)[0];
      this.quoteData.organisation = customer.organisation;
      this.quoteData.address = customer.address;
    },

    formSubmitted() {
      this.formShow = true;
      const self = this;
      this.$refs.surveyRules.validate().then((success) => {
        if (success) {
          this.formShow = false;
        } else {
          this.formShow = false;
        }
      });
    },
    addNewContractsInContractForm() {
      this.$refs.contractForm.style.overflow = 'hidden';
      this.quoteData.contracts.push(JSON.parse(JSON.stringify(this.contractFormBlankContract)));

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.contractForm.style.overflow = null;
        }, 350);
      });
    },
    removeContractFormItem(index) {
      this.quoteData.contracts.splice(index, 1);
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#eventLabel__BV_label_ {
  text-decoration: underline;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}
</style>
